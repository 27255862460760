/**
 * Berechtigungstool
 * ...
 *
 * The version of the OpenAPI document: v1.0.0
 * 
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 */
import { DynamicRuleEntity } from './dynamic-rule-entity';
import { InMemoryPermissionService } from './in-memory-permission-service';
import { RuleEntity } from './rule-entity';


export interface RuleSetEntity { 
    inMemoryPermissionService?: InMemoryPermissionService;
    rules?: Array<RuleEntity>;
    ruleConnector?: RuleSetEntity.RuleConnectorEnum;
    customAnswer?: string;
    stack?: Array<string>;
    dr?: DynamicRuleEntity;
}
export namespace RuleSetEntity {
    export type RuleConnectorEnum = 'AND' | 'OR';
    export const RuleConnectorEnum = {
        And: 'AND' as RuleConnectorEnum,
        Or: 'OR' as RuleConnectorEnum
    };
}


