/**
 * User - Service
 * Der user-service aggregiert Benutzertyp übergreifend Benutzer (Innendienst, Außendienst, Mitbenutzer, Assistenten etc.) und gibt deren \"Basisstammdaten\" zurück. Der Service kann im Kontext eines angemeldeten Benutzers ausgeführt werden, so wie als technischer Service aufgerufen werden. Im Kontext eines angemeldeten Benutzers liest der Service nur die Benutzerdaten des angemeldeten Benutzers.
 *
 * The version of the OpenAPI document: v2.0.0
 * 
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 */


export interface MatomoUser { 
    /**
     * Benutzerkennung
     */
    userId?: string;
    /**
     * Im Kontext von Matomo kein INNENDIENST
     */
    userTyp?: MatomoUser.UserTypEnum;
    /**
     * Praxisstufe
     */
    praxisStufe?: string;
    /**
     * Strukturstufe
     */
    strukturstufe?: string;
    /**
     * Altersgruppe: unter 30 Jahren (< 30 Jahre), 30-40 Jahre (>= 30 Jahre & < 40 Jahre), 40-50 Jahre (>= 40 Jahre & < 50 Jahre), über 50 Jahre (>= 50 Jahre)
     */
    ageGroup?: MatomoUser.AgeGroupEnum;
    /**
     * Betriebszugehörigkeit: unter einem Jahr (< 1 Jahr), 1-3 Jahre (>= 1 Jahr & < 3 Jahre), 3-10 Jahre (>= 3 Jahr & < 10 Jahre), über 10 Jahre (>= 10 Jahre)
     */
    periodOfEmployment?: MatomoUser.PeriodOfEmploymentEnum;
    /**
     * Clubzugehörigkeit
     */
    clubMembership?: string;
    /**
     * Direktionsnummer
     */
    direktionsnummer?: number;
    /**
     * Wenn \'true\' soll der Benutzer nicht getracked werden, z.B. im Fall eines Innendienstbenutzer
     */
    doNotTrack?: boolean;
}
export namespace MatomoUser {
    export type UserTypEnum = 'AUSSENDIENST' | 'INNENDIENST' | 'MITBENUTZER' | 'HOTLINE' | 'AUTOTEST' | 'KONZERN_MITARBEITER' | 'SCHUELER' | 'ASSISTENT' | 'SERVICE_ACCOUNT';
    export const UserTypEnum = {
        Aussendienst: 'AUSSENDIENST' as UserTypEnum,
        Innendienst: 'INNENDIENST' as UserTypEnum,
        Mitbenutzer: 'MITBENUTZER' as UserTypEnum,
        Hotline: 'HOTLINE' as UserTypEnum,
        Autotest: 'AUTOTEST' as UserTypEnum,
        KonzernMitarbeiter: 'KONZERN_MITARBEITER' as UserTypEnum,
        Schueler: 'SCHUELER' as UserTypEnum,
        Assistent: 'ASSISTENT' as UserTypEnum,
        ServiceAccount: 'SERVICE_ACCOUNT' as UserTypEnum
    };
    export type AgeGroupEnum = 'unter 30 Jahren' | '30-40 Jahre' | '40-50 Jahre' | 'ueber 50 Jahre' | 'n/a';
    export const AgeGroupEnum = {
        Unter30Jahren: 'unter 30 Jahren' as AgeGroupEnum,
        _3040Jahre: '30-40 Jahre' as AgeGroupEnum,
        _4050Jahre: '40-50 Jahre' as AgeGroupEnum,
        Ueber50Jahre: 'ueber 50 Jahre' as AgeGroupEnum,
        NA: 'n/a' as AgeGroupEnum
    };
    export type PeriodOfEmploymentEnum = 'unter einem Jahr' | '1-3 Jahre' | '3-10 Jahre' | 'ueber 10 Jahre' | 'n/a';
    export const PeriodOfEmploymentEnum = {
        UnterEinemJahr: 'unter einem Jahr' as PeriodOfEmploymentEnum,
        _13Jahre: '1-3 Jahre' as PeriodOfEmploymentEnum,
        _310Jahre: '3-10 Jahre' as PeriodOfEmploymentEnum,
        Ueber10Jahre: 'ueber 10 Jahre' as PeriodOfEmploymentEnum,
        NA: 'n/a' as PeriodOfEmploymentEnum
    };
}


